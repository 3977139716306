<template>
  <tr class="dispatch-trip-row">
    <td>
      <div class="trip-courier">
        {{ trip.fulfilmentUser && trip.fulfilmentUser.name }}
      </div>
      <div class="trip-num">
        {{ $t('packAndDispatch.table.tripNum', { tripNum: trip.id }) }}
      </div>
    </td>
    <td>
      <b-button
        class="is-primary trip-orders-breakdown"
        :class="{'is-danger': hasCancelledTask}"
        @click="showTripTasks">
        {{ tripDeliveries }}
      </b-button>
    </td>
    <td>
      <b-input
        :value="trip.packerName"
        :placeholder="$t('packAndDispatch.table.packerPlaceholder')"
        @input="localPackerName = $event"
        @blur="updateTripPackerName"/>
    </td>
    <td>
      {{ leaveByTime }}
    </td>
    <td class="trip-ready-at">
      <b-button
        v-if="!trip.packedAt"
        type="is-primary is-outlined"
        @click="markAsPacked">
        {{ $t('packAndDispatch.table.markPackedButtonTitle') }}
      </b-button>
      <template v-else>
        {{ formatTimestamp(trip.packedAt) }}
        <component
          :is="!trip.startedAt ? 'span' : 'b-tooltip'"
          :label="$t('packAndDispatch.table.cancelPackedDisabledTooltip')"
          type="is-dark"
          animated>
          <b-icon
            icon="times"
            size="is-small"
            type="is-danger"
            class="icon-cancel"
            :class="{'disabled': trip.startedAt}"
            @click.native="trip.startedAt ? null : cancelPacked()"/>
        </component>
      </template>
    </td>
    <td class="trip-out-at">
      <component
        :is="trip.packedAt ? 'span' : 'b-tooltip'"
        v-if="!trip.startedAt"
        :label="$t('packAndDispatch.table.markStartedDisabledTooltip')"
        type="is-dark"
        animated>
        <b-button
          type="is-primary is-outlined"
          :disabled="!trip.packedAt"
          @click="markAsStarted">
          {{ $t('packAndDispatch.table.markStartedButtonTitle') }}
        </b-button>
      </component>
      <template v-else>
        {{ formatTimestamp(trip.startedAt) }}
        <b-icon
          icon="times"
          size="is-small"
          type="is-danger"
          class="icon-cancel"
          @click.native="cancelStarted"/>
      </template>
    </td>
    <td>
      <b-input
        :id="`input_notes_${trip.id}`"
        :value="trip.notes"
        type="textarea"
        :placeholder="$t('packAndDispatch.table.notesPlaceholder')"
        @input="onNotesInput"
        @blur="updateTripNotes"/>
    </td>
    <td>
      <b-tag
        v-if="status"
        :type="status.class || 'is-dark'">
        {{ status.message }}
      </b-tag>
    </td>
  </tr>
</template>

<script>
import { TRIP_STATES } from '@js/constants'
import { formatTimeFromDate } from '@js/utils'

const ONE_MINUTE_IN_MS = 1000 * 60
const FOUR_HOURS_IN_MS = ONE_MINUTE_IN_MS * 60 * 4

export default {
  name: 'dispatch-trip-row',
  props: {
    trip: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      refreshCurrentTimeInterval: null,
      currentTime: null,
      localPackerName: null,
      localTripNotes: null
    }
  },
  computed: {
    hasCancelledTask() {
      return this.trip.tasks.some(task => task.isCancelled)
    },
    tripDeliveries() {
      let pickupSummary, deliverySummary
      const pickupTasksCount = this.trip.tasks.filter(task => task.isPickup).length
      const deliveryTasksCount = this.trip.tasks.filter(task => !task.isPickup).length
      if (pickupTasksCount > 0) {
        pickupSummary = this.$tc('packAndDispatch.table.tasks.pickups', pickupTasksCount, { count: pickupTasksCount })
      }
      if (deliveryTasksCount > 0) {
        deliverySummary = this.$tc('packAndDispatch.table.tasks.deliveries', deliveryTasksCount, { count: deliveryTasksCount })
      }

      let summary
      if (pickupSummary && deliverySummary) {
        summary = this.$t('packAndDispatch.table.tasks.pickupsAndDeliveries', { pickups: pickupSummary, deliveries: deliverySummary })
      } else {
        summary = pickupSummary || deliverySummary
      }

      const externalLogisticsTask = this.trip.tasks.find(task => task.externalLogisticsId)
      if (externalLogisticsTask) {
        const shortId = this.$t(`common.task.shortId.${externalLogisticsTask.externalLogisticsType || 'pickupp'}`, { shortId: externalLogisticsTask.shortId })
        return this.$t('packAndDispatch.table.tasks.summaryWithShortId', { shortId, summary })
      }

      return summary
    },
    leaveByTime() {
      if (this.trip.leaveBy) {
        return formatTimeFromDate(this.trip.leaveBy)
      }
      return null
    },
    status() {
      let leaveByDateTime
      let klass
      switch (this.trip.state) {
        case TRIP_STATES.ASSIGNED:
        case TRIP_STATES.PACKED:
          leaveByDateTime = new Date(this.trip.leaveBy)
          if (this.currentTime > leaveByDateTime) {
            const lateBy = Math.ceil((this.currentTime - leaveByDateTime) / ONE_MINUTE_IN_MS)
            return {
              class: 'is-danger-light',
              message: this.$tc('packAndDispatch.trip.status.late', lateBy, { lateBy })
            }
          }
          break
        case TRIP_STATES.COMPLETED:
          return {
            class: 'is-success',
            message: this.$t('packAndDispatch.trip.status.completed')
          }
        case TRIP_STATES.FAILED:
          return {
            class: 'is-danger',
            message: this.$t('packAndDispatch.trip.status.failed')
          }
        default:
          // check if dispatched later than leave by time
          if (new Date(this.trip.startedAt) > new Date(this.trip.leaveBy)) {
            klass = 'is-danger-light'
          } else {
            klass = 'is-success-light'
          }
          return {
            class: klass,
            message: this.trip.status
          }
      }
      return null
    }
  },
  watch: {
    trip: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.updateNotesInputHeight()
        })

        if (this.refreshCurrentTimeInterval) clearInterval(this.refreshCurrentTimeInterval)
        // refresh only if trip is not completed/failed, and leave by time is less than 4 hours away
        if (![TRIP_STATES.COMPLETED, TRIP_STATES.FAILED].includes(this.trip.state)) {
          if (new Date(this.trip.leaveBy) - new Date() < FOUR_HOURS_IN_MS) {
            this.currentTime = new Date()
            this.refreshCurrentTimeInterval = setInterval(() => {
              this.currentTime = new Date()
            }, ONE_MINUTE_IN_MS)
          }
        }
      }
    }
  },
  methods: {
    formatTimestamp(date) {
      return new Date(date).toLocaleTimeString()
    },
    showTripTasks() {
      this.$emit('show-trip-tasks', this.trip)
    },
    updateTripPackerName() {
      // only update if there is a change
      if (this.localPackerName !== null && this.trip.packerName !== this.localPackerName) {
        this.$emit('update-packer', this.trip.id, this.localPackerName)
      }
    },
    markAsPacked() {
      this.$emit('mark-packed', this.trip.id)
    },
    markAsStarted() {
      this.$emit('mark-started', this.trip.id)
    },
    cancelPacked() {
      this.$emit('cancel-packed', this.trip.id)
    },
    cancelStarted() {
      this.$emit('cancel-started', this.trip.id)
    },
    onNotesInput(value) {
      this.localTripNotes = value
      this.updateNotesInputHeight()
    },
    updateNotesInputHeight() {
      const element = document.getElementById(`input_notes_${this.trip.id}`)
      // set these first to get the expected height
      element.style.overflowY = 'hidden'
      element.style.height = ''

      const borderHeight = 2
      // set height based on scrollHeight first, before removing overflow-y
      element.style.height = element.scrollHeight + borderHeight + 'px'
      element.style.overflowY = ''
    },
    updateTripNotes() {
      // only update if there is a change
      if (this.localTripNotes !== null && this.trip.notes !== this.localTripNotes) {
        this.$emit('update-notes', this.trip.id, this.localTripNotes)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dispatch-trip-row {
  .trip-courier {
    font-weight: bold;
  }

  .button {
    @extend %body;
  }

  .trip-ready-at,
  .trip-out-at {
    white-space: nowrap;
  }

  .icon-cancel {
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
    }
  }

  &::v-deep .control {
    .input,
    .textarea {
      @extend %body;

      &:not(:focus):not(:hover) {
        border-color: transparent;
        box-shadow: none;
      }
    }

    .input {
      max-width: 150px;
    }

    .textarea {
      min-height: 0;
      max-height: 90px;
      padding: $space-xxs $space-xs;
      resize: none;
      @extend %scrollbar;
    }
  }
}
</style>
